<template>
  <v-card
    elevation="6"
    :to="{ name: 'fuente', params: { nombre: info.origen, info: info } }"
    @click="$store.commit('account/setSourceResults', info)"
  >
    <v-img :src="info.imagen"></v-img>
    <v-card-title class="pb-0">
      {{ info.origen }}
    </v-card-title>
    <v-card-text>
      <div class="my-0 subtitle-1 text-subtitle-2">
        {{ info.resultados }} {{ setResults }}
      </div>
      <div class="subtitle-1 text-end">Empresas: {{ info.empresas }}</div>
      <div class="subtitle-1 text-end">Vacantes: {{ info.vacantes }}</div>
      <div class="subtitle-1 text-end">Localidades: {{ info.localidades }}</div>
      <v-divider class="mx-0"></v-divider>
      <div class="my-2 subtitle-1 text-end">Candidatos: {{ info.candidatos }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    info: { type: Object, required: true },
  },
  computed: {
    setResults() {
      let newResults;
      switch (this.info.origen) {
        case "Twitter":
          newResults = "Tweets de 157";
          break;
        case "OCC":
          newResults = "Páginas de 843";
          break;
        default:
          newResults = "Páginas de 1000";
          break;
      }
      return newResults;
    },
  },
};
</script>

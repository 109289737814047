<template>
  <v-card elevation="6" class="info-card mt-3">
    <v-sheet :color="info.color" dark rounded elevation="6" class="pa-3 info-card-icon">
      <v-icon x-large>{{ info.icon }}</v-icon>
    </v-sheet>
    <v-card-subtitle class="text-right text-uppercase text-caption pt-2 pb-0">
      {{ info.name }}
    </v-card-subtitle>
    <v-card-text class="text-right text-overline pt-0 pb-2">
      <h1>{{ info.count }}</h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    info: { type: Object, required: true },
  },
};
</script>

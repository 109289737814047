<template>
  <v-container v-if="searchResults != null">
    <!-- <v-container> -->
    <v-row v-scrollfadein>
      <v-col>
        <span class="text-h6">Resultados para "{{ searchResults.message }}"</span>
        <span class="text-caption text--disabled">
          en {{ (searchResults.responsetime / 1000).toFixed(2) }} s.
        </span>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <v-text-field
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="getSearch()"
          v-model="busqueda"
          clearable
          label="Buscar"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify-sm="center" class="mb-2" v-if="getInfoCards" v-scrollfadein>
      <v-col cols="12" sm="12" md="3" v-for="(item, index) in getInfoCards" :key="index">
        <InfoCard :info="item" />
      </v-col>
    </v-row>
    <v-row justify-sm="center" v-if="getSourceCards" v-scrollfadein>
      <v-col
        cols="12"
        sm="12"
        md="3"
        v-for="(item, index) in getSourceCards"
        :key="index"
      >
        <SourceCard :info="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import SourceCard from "@/components/SourceCard";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {
    InfoCard,
    SourceCard,
  },
  data() {
    return {
      busqueda: null,
    };
  },
  methods: {
    getSearch() {
      let busqueda = this.busqueda ? this.busqueda : "chofer";
      if (
        this.searchResults == null ||
        this.busqueda != this.searchWord ||
        this.busqueda == null
      ) {
        let data = new FormData();

        this.$store.commit("account/setResult", null);
        this.$store.commit("account/setSearchWord", busqueda);
        data.append("search", busqueda);

        axios
          .post("public/discovery", data, { config: { showOverlay: true } })
          .then((response) => {
            this.$store.commit("account/setResult", response.data);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
  },
  computed: {
    ...mapState("account", ["searchResults", "searchWord"]),
    ...mapState(["overlay"]),
    getInfoCards() {
      if (this.searchResults != null) {
        return [
          {
            icon: "mdi-account-tie",
            color: "primary",
            name: "Candidatos",
            count: this.searchResults.candidatos,
          },
          {
            icon: "mdi-domain",
            color: "success",
            name: "Vacantes",
            count: this.searchResults.vacante,
          },
          {
            icon: "mdi-earth",
            color: "warning",
            name: "Otros",
            count: this.searchResults.otros,
          },
        ];
      } else {
        return null;
      }
    },
    getSourceCards() {
      if (this.searchResults != null) {
        let sourceCards = this.searchResults.fuentes;
        sourceCards[0].imagen = require("@/assets/images/cp.png");
        sourceCards[1].imagen = require("@/assets/images/occ.png");
        sourceCards[2].imagen = require("@/assets/images/tw.png");
        return sourceCards;
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.busqueda = this.searchWord;
  },
  mounted() {
    this.getSearch(this.busqueda);
  },
};
</script>
